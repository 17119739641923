import { createAsyncThunk } from "@reduxjs/toolkit";

import { getAPI, patchAPI, postAPI } from "../../axios/utils";
import {
  CONFIRM_GUEST_API,
  EVENT_PAGE_TEXT,
  INVITE_GUEST_API,
  TICKET_COUPON_CODE_VALIDATE_API,
  TICKET_SALE_SETTINGS_API,
} from "../../utils/constants";

export const getTicketSaleSettings = createAsyncThunk("tickets/getTicketSaleSettings", async () => {
  return getAPI(TICKET_SALE_SETTINGS_API);
});

export const getEventPageText = createAsyncThunk("tickets/getEventPageText", async () => {
  return getAPI(EVENT_PAGE_TEXT);
});

export const validateTicketCouponCode = createAsyncThunk("tickets/validateTicketCouponCode", async (data: Record<string, any>) => {
  return postAPI(TICKET_COUPON_CODE_VALIDATE_API, data);
});

export const confirmGuestTicket = createAsyncThunk("tickets/confirmGuestTicket", async (data: Record<string, any>) => {
  return patchAPI(CONFIRM_GUEST_API, data);
});

export const inviteGuest = createAsyncThunk("tickets/inviteGuest", async (data: Record<string, any>) => {
  return postAPI(INVITE_GUEST_API, data);
});
