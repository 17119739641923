import Logout from "../pages/Logout";
import AboutPage from "../pages/about";
import AccountPage from "../pages/account";
import Contact from "../pages/contact";
import DreamHubPage from "../pages/dreamHub";
import ForgotPassword from "../pages/forgotPassword";
import SetPassword from "../pages/forgotPassword/SetPassword";
import HomePage from "../pages/home";
import LivestreamTicketPurchasePage from "../pages/livestream";
import LogInPage from "../pages/login";
import MagazinePage from "../pages/magazine";
import PartnerPortal from "../pages/partnerPortal";
import PortfolioPage from "../pages/portfolio";
import PortfolioProjectPage from "../pages/portfolioProject";
import PressPage from "../pages/press";
import RegisterPage from "../pages/register";
import ServicesPage from "../pages/services";
import SponsorGuests from "../pages/sponsorGuests";
import XLabPage from "../pages/xlab";
import XMPage from "../pages/xm";

export interface DrawerMenuItemsInterface {
  text: string;
  path: string;
  icon: React.ReactNode;
}

export interface RoutesInterface {
  path: string;
  title: string;
  icon: React.ReactNode | null;
  isLoginRequired: boolean;
  page: React.ReactNode;
}

export interface RoutesGroupInterface {
  startPage: RoutesInterface;
  login: RoutesInterface;
  register: RoutesInterface;
  forgotPassword: RoutesInterface;
  setForgotPassword: RoutesInterface;
  logout: RoutesInterface;
  account: RoutesInterface;
  press: RoutesInterface;
  portfolio: RoutesInterface;
  portfolioProject: RoutesInterface;
  about: RoutesInterface;
  magazine: RoutesInterface;
  contact: RoutesInterface;
  home: RoutesInterface;
  livestreamTicket: RoutesInterface;
  services: RoutesInterface;
  sponsorGuests: RoutesInterface;
  dreamHub: RoutesInterface;
  xlab: RoutesInterface;
  xm: RoutesInterface;
  partnerTicketPurchase: RoutesInterface;
  [x: string]: RoutesInterface;
}

export const routes: RoutesGroupInterface = {
  startPage: {
    path: "/",
    title: "Home",
    icon: null,
    isLoginRequired: false,
    page: <LogInPage />,
  },
  login: {
    path: "/login",
    title: "Log In",
    icon: null,
    isLoginRequired: false,
    page: <LogInPage />,
  },
  register: {
    path: "/register",
    title: "Register",
    icon: null,
    isLoginRequired: false,
    page: <RegisterPage />,
  },
  forgotPassword: {
    path: "/forgot-password",
    title: "Forgot Password",
    icon: null,
    isLoginRequired: false,
    page: <ForgotPassword />,
  },
  setForgotPassword: {
    path: "/set-password/:uid/:token",
    title: "Set New Password",
    icon: null,
    isLoginRequired: false,
    page: <SetPassword />,
  },
  logout: {
    path: "/logout",
    title: "Logout",
    icon: null,
    isLoginRequired: true,
    page: <Logout />,
  },
  account: {
    path: "/account",
    title: "Account",
    icon: null,
    isLoginRequired: false,
    page: <AccountPage />,
  },
  press: {
    path: "/press",
    title: "Press",
    icon: null,
    isLoginRequired: false,
    page: <PressPage />,
  },
  portfolio: {
    path: "/portfolio",
    title: "Design Services",
    icon: null,
    isLoginRequired: false,
    page: <PortfolioPage />,
  },
  portfolioProject: {
    path: "/portfolio/:projectSlug",
    title: "Portfolio Project",
    icon: null,
    isLoginRequired: false,
    page: <PortfolioProjectPage />,
  },
  about: {
    path: "/about",
    title: "About",
    icon: null,
    isLoginRequired: false,
    page: <AboutPage />,
  },
  magazine: {
    path: "/magazine",
    title: "Magazine",
    icon: null,
    isLoginRequired: false,
    page: <MagazinePage />,
  },
  contact: {
    path: "/contact",
    title: "Contact Us",
    icon: null,
    isLoginRequired: false,
    page: <Contact />,
  },
  partnerTicketPurchase: {
    path: "/:partnerSlug/",
    title: "Partner Ticket Purchase",
    icon: null,
    isLoginRequired: false,
    page: <PartnerPortal />,
  },

  // Private
  home: {
    path: "/home",
    title: "Home",
    icon: null,
    isLoginRequired: true,
    page: <HomePage />,
  },
  livestreamTicket: {
    path: "/livestream/purchase/ticket",
    title: "Livestream Ticket",
    icon: null,
    isLoginRequired: true,
    page: <LivestreamTicketPurchasePage />,
  },
  services: {
    path: "/services",
    title: "Services",
    icon: null,
    isLoginRequired: true,
    page: <ServicesPage />,
  },
  sponsorGuests: {
    path: "/sponsor-guests",
    title: "Guests",
    icon: null,
    isLoginRequired: true,
    page: <SponsorGuests />,
  },
  dreamHub: {
    path: "/dream-hub",
    title: "Dream Hub",
    icon: null,
    isLoginRequired: true,
    page: <DreamHubPage />,
  },
  xlab: {
    path: "/xlab",
    title: "XLAB",
    icon: null,
    isLoginRequired: true,
    page: <XLabPage />,
  },
  xm: {
    path: "/xm",
    title: "XM",
    icon: null,
    isLoginRequired: true,
    page: <XMPage />,
  },
};

export const routesArr: [string, RoutesInterface][] = Object.entries(routes);

export const drawerMenuItems = routesArr
  .filter((route: any): boolean => route[1].isLoginRequired && !route[1].isHiddenOnSideDrawer && !route[1].isAdditional)
  .map(
    (route: any): DrawerMenuItemsInterface => ({
      text: route[1].title,
      path: route[1].path,
      icon: route[1].icon,
    })
  );

export const additionalDrawerMenuItems = routesArr
  .filter((route: any): boolean => route[1].isLoginRequired && !route[1].isHiddenOnSideDrawer && route[1].isAdditional)
  .map(
    (route: any): DrawerMenuItemsInterface => ({
      text: route[1].title,
      path: route[1].path,
      icon: route[1].icon,
    })
  );
